.Card {
  border: 1px solid #eee;
  margin: 0 0.5em 1em;
  padding: 1em;
  position: relative;
  .btn {
    &-close {
      background: black;
      border-radius: 50px;
      cursor: pointer;
      height: 10px;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 10px;
    }
    &-container {
      display: flex;
      button {
        border: solid 1px #ccc;
        cursor: pointer;
        margin-top: 0.5em;
        padding: 0.25em 0.5em;
        width: 100%;
        &:active {
          background: #ccc;
          color: white;
        }
        &:focus {
          outline: none;
        }
        &:hover {
          color: #666;
          &:disabled {
            color: #ccc;
            cursor: default;
          }
        }
        &:nth-child(n + 2) {
          border-left: none;
        }
      }
    }
  }
  .data {
    display: flex;
    flex-direction: column;
  }
  .status {
    max-width: 225px;
    &.error {
      color: red;
    }
    &.success {
      color: green;
    }
  }
}
